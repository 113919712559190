import React, { useEffect, useState } from 'react';
import { List, ListItem, Typography, Box } from '@mui/material';
import { useLocations } from './LocationListProvider.js';
import { useAppSelector } from '../redux/hooks.js';
import moment from 'moment';
function formatLength(length, lengthUnit) {
    if (lengthUnit === 'in') {
        const inches = length / 2.54;
        return `${inches.toFixed(1)} in`;
    }
    else {
        return `${length} cm`;
    }
}
function formatDuration(duration) {
    const parts = [];
    if (duration.days > 0)
        parts.push(`${duration.days} d`);
    if (duration.hours > 0)
        parts.push(`${duration.hours} hr`);
    if (duration.minutes > 0)
        parts.push(`${duration.minutes} min`);
    return parts.join(' ');
}
export function formatDateTime(input, inputFormat, startDate) {
    if (!startDate) {
        return input;
    }
    // const inputDate = moment(input, inputFormat);
    // if (inputDate.isSame(startDate, 'day')) {
    if (inputFormat === 'DD MMM HH:mm') {
        return moment(input).format('HH:mm');
    }
    if (inputFormat === 'MMM DD hh:mm A') {
        return moment(input).format('hh:mm A');
    }
    // }
    return input;
}
;
export default function LocationList() {
    const locations = useLocations();
    const timeFormat = useAppSelector((state) => state.user.timeFormat);
    const lengthUnit = useAppSelector((state) => state.user.lengthUnit);
    const [isDesktop, setIsDesktop] = useState(true);
    const [startDate, setStartDate] = useState();
    useEffect(() => {
        if (locations.length >= 2 && timeFormat) {
            // Second item always guaranteed to be a visit
            setStartDate(moment(locations[1].time?.arrivalTime, timeFormat));
        }
    }, [locations, timeFormat]);
    useEffect(() => {
        const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
        const mobile = /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
        const tablet = /Tablet|iPad/i.test(userAgent);
        setIsDesktop(!mobile && !tablet);
    }, [isDesktop]);
    return (React.createElement(React.Fragment, null,
        React.createElement(List, { sx: { width: '100%' } }, locations.map((location, index) => (React.createElement(ListItem, { key: index, divider: true, sx: { display: 'flex', alignItems: 'center', width: '100%' } },
            location.type === 'visit' && (React.createElement(Box, { sx: { display: 'flex', alignItems: 'center', flex: (isDesktop ? '1' : '0.75'), justifyContent: 'flex-start' } }, location.index &&
                React.createElement(Typography, { variant: "caption", sx: { ml: 1 } }, location.index))),
            location.type === 'visit' && location.time &&
                React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column', flex: '1', alignItems: 'center' } },
                    React.createElement(Typography, { variant: "body2" }, formatDateTime(location.time.arrivalTime, timeFormat, startDate)),
                    React.createElement(Typography, { variant: "body2" }, formatDateTime(location.time.departureTime, timeFormat, startDate))),
            location.type === 'visit' &&
                React.createElement(Typography, { variant: "body1", sx: { flex: '3' } }, location.streetAddress),
            location.type === 'visit' &&
                React.createElement(Typography, { variant: "body1", sx: { flex: (isDesktop ? '1' : '1.5') } },
                    "\u2744\uFE0F ",
                    location.snowDepth ? formatLength(location.snowDepth, lengthUnit) : formatLength(0, lengthUnit)),
            location.type === 'visit' &&
                React.createElement(Typography, { variant: "body1", sx: { flex: (isDesktop ? '1' : '0.5') } },
                    "$",
                    location.revenue ? location.revenue : 0),
            location.type === 'transition' && (React.createElement(Typography, { variant: "body1", sx: { flex: '1' } }, formatDuration(location.duration).length ?
                `Drive ${location.distance.toFixed(1)} ${location.distanceUnit} for ${formatDuration(location.duration)}` :
                'No travel'))))))));
}
;
