import axios from 'axios';
import { baseURL } from '../constants/routes.js';
export default async function populateWeatherStore(weatherEventsRequest, uid, idToken) {
    let weatherEventsResponse = undefined;
    try {
        const response = await axios.post(`${baseURL}/fetch-weather-events`, {
            ...weatherEventsRequest,
            uid,
            idToken,
        });
        if (response.status === 200) {
            console.log(response.data);
            console.log(typeof response.data);
            if (response.data && !response.data.weatherEvents?.length) {
                throw new Error(`Received data from /fetch-weather-events but no weather events: ${JSON.stringify(response.data)}`);
            }
            if (response.data && !response.data.contiguousWeatherEvents?.length) {
                throw new Error(`Received data from /fetch-weather-events but no contiguous weather events : ${JSON.stringify(response.data)}`);
            }
            try {
                const weatherEventsMap = new Map(response.data.weatherEvents);
                const contiguousWeatherEventsMap = new Map(response.data.contiguousWeatherEvents);
                const weatherEvents = weatherEventsMap;
                const contiguousWeatherEvents = contiguousWeatherEventsMap;
                weatherEventsResponse = { weatherEvents, contiguousWeatherEvents };
            }
            catch (e) {
                throw new Error(`Unable to convert weatherEvents from response into a map!: ${response.data.weatherEvents}`);
            }
        }
        return weatherEventsResponse;
    }
    catch (e) {
        throw new Error(`Failed to fetch weather events for ${JSON.stringify(weatherEventsRequest)}: ${e}`);
    }
}
;
