export const isUuidV4 = (uuid) => {
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return regex.test(uuid);
};
export let baseURL = `https://simulator.mmtplanning.com`;
if (process.env && process.env.REACT_APP_ENV === 'DEV') {
    baseURL = `${process.env.REACT_APP_HTTP_PROTOCOL}://${process.env.REACT_APP_HOSTNAME}:${process.env.REACT_APP_PORT}`;
}
export const ROUTES = {
    fetchWeatherEvents: 'https://us-east4-directed-reef-420621.cloudfunctions.net/fetchWeatherEvents',
    squarePayments: 'https://connect.squareup.com/v2/payments',
    squarePaymentsSandbox: 'https://connect.squareupsandbox.com/v2/payments',
};
