import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useMapsLibrary, useMap } from '@vis.gl/react-google-maps';
import { v4 as uuidv4 } from 'uuid';
import { useAppDispatch, useAppSelector } from '../redux/hooks.js';
import { setRequestVehicles } from '../redux/simulationSlice.js';
import dayjs from 'dayjs';
import { convertToISO8601 } from '../constants/interpolationHelpers.js';
;
;
const defaultContextValue = {
    rows: [],
    addRow: () => { },
    deleteRow: () => { },
    updateRow: () => { },
    additionalContext: {
        globalStartTimeISO8601: '',
        globalEndTimeISO8601: '',
        fetchAddressSuggestions: () => { },
        setVehicles: () => { }
    }
};
export const FormContext = createContext(defaultContextValue);
export default function VehicleFormProvider({ children }) {
    const map = useMap();
    const placesLib = useMapsLibrary('places');
    const [rows, setRowsState] = useState([]);
    const dispatch = useAppDispatch();
    let globalStartTimeISO8601 = useAppSelector((state) => state.simulation.request?.optimizationRequest?.model.globalStartTime) || '';
    let globalEndTimeISO8601 = useAppSelector((state) => state.simulation.request?.optimizationRequest?.model.globalEndTime) || '';
    if (!globalStartTimeISO8601 || !globalEndTimeISO8601) {
        throw new Error('Missing global start and end times for visits');
    }
    let vehicles = useAppSelector((state) => state.simulation.request?.optimizationRequest?.model.vehicles);
    const addRow = useCallback(() => {
        const defaultTimeWindow = {
            startTime: globalStartTimeISO8601,
            endTime: globalEndTimeISO8601,
        };
        const sparseVehicle = {
            label: '///',
            startLocation: {
                latitude: 0,
                longitude: 0,
            },
            startTimeWindows: [defaultTimeWindow],
            endTimeWindows: [defaultTimeWindow],
        };
        const newRow = { id: uuidv4(), value: sparseVehicle, isDone: false };
        setRowsState(prevRows => [...prevRows, newRow]);
    }, [globalStartTimeISO8601, globalEndTimeISO8601]);
    const updateRow = useCallback((changedRow) => {
        setRowsState((prevRows) => prevRows.map(row => row.id === changedRow.id ? { ...changedRow } : row));
    }, []);
    const deleteRow = useCallback((deleteRowId) => {
        setRowsState((prevRows) => prevRows.filter(row => row.id !== deleteRowId));
    }, []);
    useEffect(() => {
        if (!vehicles || !vehicles.length) {
            return;
        }
        ;
        const updatedRows = vehicles.map((vehicle, idx) => {
            let updatedVehicle = { ...vehicle };
            if (!updatedVehicle.startLocation || !updatedVehicle.startTimeWindows || !updatedVehicle.endTimeWindows) {
                throw new Error('Malformed visit: missing start location, or time windows');
            }
            // #199: Update vehicle start/end times to be within bounds of global start and end times
            // TODO: VehicleForm should throw an error if vehicle start and end times require
            // correction because they are out of bounds.
            let vehicleStartTime = dayjs(updatedVehicle.startTimeWindows[0].startTime);
            let vehicleEndTime = dayjs(updatedVehicle.endTimeWindows[0].endTime);
            updatedVehicle = {
                ...updatedVehicle,
                startTimeWindows: [{
                        startTime: convertToISO8601(vehicleStartTime),
                        endTime: convertToISO8601(vehicleEndTime),
                    }],
                endTimeWindows: [{
                        startTime: convertToISO8601(vehicleStartTime),
                        endTime: convertToISO8601(vehicleEndTime),
                    }],
            };
            if (!updatedVehicle.label) {
                const temporaryVehicleName = `Vehicle #${idx + 1}`;
                const temporaryVehicleAddress = `${vehicle.startLocation.latitude.toFixed(4)},${vehicle.startLocation.longitude.toFixed(4)}`;
                updatedVehicle = {
                    ...updatedVehicle,
                    label: `${temporaryVehicleName}///${temporaryVehicleAddress}`
                };
            }
            return {
                id: uuidv4(),
                value: updatedVehicle,
                isDone: true
            };
        });
        setRowsState(updatedRows);
    }, [vehicles]);
    const setVehicles = useCallback((vehicles) => {
        dispatch(setRequestVehicles(vehicles));
    }, [dispatch]);
    const fetchAddressSuggestions = useCallback((inputRef, getAutocompleteAddress) => {
        if (!map || !placesLib || !inputRef.current)
            return;
        const autocompleteService = new placesLib.Autocomplete(inputRef.current, {
            bounds: map.getBounds(),
            fields: ["formatted_address", "geometry.location"],
            types: ['geocode'],
        });
        const updateAutocompleteBounds = () => {
            const bounds = map.getBounds();
            autocompleteService.setBounds(bounds);
        };
        const onPlaceChanged = () => {
            const place = autocompleteService.getPlace();
            if (place.formatted_address && place.geometry?.location) {
                getAutocompleteAddress(place.formatted_address, place.geometry.location);
            }
        };
        autocompleteService.addListener('place_changed', onPlaceChanged);
        map.addListener('bounds_changed', updateAutocompleteBounds);
        // Cleanup function to remove the listeners
        return () => {
            window.google.maps.event.clearInstanceListeners(autocompleteService);
            window.google.maps.event.clearInstanceListeners(map);
        };
    }, [map, placesLib]);
    const vehicleFormContextValue = {
        rows,
        addRow,
        deleteRow,
        updateRow,
        additionalContext: {
            globalStartTimeISO8601,
            globalEndTimeISO8601,
            fetchAddressSuggestions,
            setVehicles
        }
    };
    return (React.createElement(FormContext.Provider, { value: vehicleFormContextValue }, children));
}
;
export function useFormContext() {
    return useContext(FormContext);
}
