import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { getAuth, GoogleAuthProvider, isSignInWithEmailLink, onIdTokenChanged, sendSignInLinkToEmail, signInAnonymously, signInWithEmailLink, signInWithPopup, signOut } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
// TODO: Move to process env variables in CloudRun
const firebaseConfig = {
    apiKey: "AIzaSyBIjxWXs88gE78R79o_F1UcgzcItlYFyYA",
    authDomain: "directed-reef-420621.firebaseapp.com",
    projectId: "directed-reef-420621",
    storageBucket: "directed-reef-420621.appspot.com",
    messagingSenderId: "565284456695",
    appId: "1:565284456695:web:ee1f7e7faa49a41e242bf2",
    measurementId: "G-TWNT2KF8X4"
};
const app = initializeApp(firebaseConfig);
const auth = getAuth();
const TOKEN_REFRESH_INTERVAL_IN_MS = 59 * 60 * 1000;
export const AuthContext = createContext({
    app: null,
    currentUser: null,
    idToken: '',
    handleGoogleSignIn: async function () { },
    logout: async function () { },
    sendEmailSignInLink: async function () { },
});
export default function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(null);
    const [idToken, setIdToken] = useState('');
    useEffect(() => {
        if (isSignInWithEmailLink(auth, window.location.href)) {
            let email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
                throw new Error(`Are you using (1) incognito OR (2) a different browser from the one you requested sign-in?`);
            }
            signInWithEmailLink(auth, email)
                // TODO: verify email! is necessary here.
                .then(async (result) => {
                window.localStorage.removeItem('emailForSignIn');
                setCurrentUser(result.user);
                setIdToken(await result.user.getIdToken());
            })
                .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                throw new Error(`Failed to sign in with email link w/ errorCode:${errorCode} errorMessage:${errorMessage}`);
            });
            return;
        }
        ;
        const unsubscribe = onIdTokenChanged(auth, async (user) => {
            if (!user) {
                try {
                    await signInAnonymously(auth);
                    const anonymousUser = auth.currentUser;
                    setCurrentUser(anonymousUser);
                    setIdToken(await anonymousUser.getIdToken());
                }
                catch (error) {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    throw new Error(`Failed to sign in anonymously w/ errorCode:${errorCode} errorMessage:${errorMessage}`);
                }
            }
            else {
                setCurrentUser(user);
                setIdToken(await user.getIdToken(true)); // forcing tokens refresh
            }
        });
        return () => unsubscribe();
    }, []);
    useEffect(() => {
        const interval = setInterval(async () => {
            if (auth.currentUser) {
                const token = await auth.currentUser.getIdToken(true);
                setIdToken(token);
            }
        }, TOKEN_REFRESH_INTERVAL_IN_MS);
        return () => clearInterval(interval);
    }, []);
    const handleGoogleSignIn = useCallback(async () => {
        if (!currentUser) {
            throw new Error(`Can't link to Google account: no existing anon session found for user`);
        }
        const auth = getAuth();
        const provider = new GoogleAuthProvider();
        const prevSimulationId = ''; // TODO: get the pending simulation the current user has 
        signInWithPopup(auth, provider).then(async (result) => {
            const credential = GoogleAuthProvider.credentialFromResult(result);
            if (!credential) {
                throw new Error(`Could not get GoogleAuthProvider credential for ${result.user.email} ${result.user.uid}`);
            }
            const user = result.user;
            setCurrentUser(user);
            setIdToken(await user.getIdToken());
            // TODO: add prevSimulationId to migrated account
        }).catch((error) => {
            throw new Error(`Authorization link with Google failed for uid ${currentUser.uid}: ${error.message}`);
        });
    }, [currentUser]);
    const sendEmailSignInLink = async (email, refUrl) => {
        const auth = getAuth();
        const actionCodeSettings = {
            url: refUrl,
            handleCodeInApp: true,
        };
        try {
            await sendSignInLinkToEmail(auth, email, actionCodeSettings);
            window.localStorage.setItem('emailForSignIn', email);
            console.log(`Sign-in link sent! for email ${email}`);
        }
        catch (error) {
            throw new Error(`Failed to send sign-in link to email ${email} ${error}`);
        }
    };
    const logout = useCallback(async () => {
        const auth = getAuth();
        try {
            await signOut(auth);
            setCurrentUser(null);
            setIdToken('');
        }
        catch (error) {
            throw new Error(`Error logging out for current user ${currentUser}`);
        }
    }, [currentUser]);
    const authContextValue = {
        app,
        currentUser,
        handleGoogleSignIn,
        logout,
        idToken,
        sendEmailSignInLink,
    };
    return (React.createElement(AuthContext.Provider, { value: authContextValue }, children));
}
;
export function useAuthContext() {
    return useContext(AuthContext);
}
