import axios from "axios";
import { baseURL } from "../constants/routes.js";
export default async function fetchSimulationRequest(solutionUrl, uid, idToken) {
    let solutionInput = {};
    try {
        const response = await axios.post(`${baseURL}/fetch-simulation-request`, {
            solutionUrl,
            uid,
            idToken,
        });
        if (response.status === 200) {
            solutionInput = response.data;
        }
        else if (response.status === 404) {
            throw new Error(`Solution URL ${solutionUrl} provided, but no input found`);
        }
    }
    catch (e) {
        throw new Error(`Solution URL ${solutionUrl} provided, but error: ${e}`);
    }
    if (!solutionInput.optimizationRequest || !Object.keys(solutionInput.optimizationRequest).length ||
        !Object.keys(solutionInput.optimizationRequest.model.shipments).length ||
        !Object.keys(solutionInput.optimizationRequest.model.vehicles).length) {
        throw new Error(`Solution input is missing shipments or vehicles for solutionUrl ${solutionUrl}`);
    }
    return solutionInput;
}
;
