import React, { createContext, useContext, useMemo } from 'react';
import { useAppSelector } from '../redux/hooks.js';
import { useRouteWeatherData } from './RouteWeatherProvider.js';
import { convertToISO8601, handleTimeFormat, interpolateSnowDepth, roundToNearestHours } from '../constants/interpolationHelpers.js';
import dayjs from 'dayjs';
export const LocationContext = createContext([]);
function getSnowDepth(coords, time, enhancedWeatherEvents) {
    let DEFAULT_SNOW_DEPTH = 0;
    let snowDepthAtArrival = DEFAULT_SNOW_DEPTH;
    const latLngStr = `${coords.lat.toFixed(2)}_${coords.lng.toFixed(2)}`;
    const events = enhancedWeatherEvents.get(latLngStr);
    if (!events)
        return DEFAULT_SNOW_DEPTH;
    const snowDepthAtTime = events;
    const roundedArrivalTimes = roundToNearestHours(dayjs(time));
    const snowDepth1 = snowDepthAtTime.get(convertToISO8601(roundedArrivalTimes.hourBefore));
    const snowDepth2 = snowDepthAtTime.get(convertToISO8601(roundedArrivalTimes.hourAfter));
    if (snowDepth1 && snowDepth2) {
        snowDepthAtArrival = interpolateSnowDepth(roundedArrivalTimes.hourBefore, snowDepth1, roundedArrivalTimes.hourAfter, snowDepth2, dayjs(time)) || DEFAULT_SNOW_DEPTH;
        if (!snowDepthAtArrival) {
            snowDepthAtArrival = snowDepthAtTime.get(time) || DEFAULT_SNOW_DEPTH;
        }
    }
    return snowDepthAtArrival || DEFAULT_SNOW_DEPTH;
}
export default function LocationListProvider({ children }) {
    const locations = useAppSelector((state) => state.location.route?.locationList) || [];
    const timeFormat = useAppSelector((state) => state.user.timeFormat);
    const routeWeatherContext = useRouteWeatherData();
    let enhancedWeatherEvents = useMemo(() => {
        if (routeWeatherContext &&
            'weatherEvents' in routeWeatherContext &&
            routeWeatherContext.weatherEvents.size > 0) {
            const weatherEvents = routeWeatherContext.weatherEvents;
            const enhancedWeatherEvents = new Map();
            weatherEvents.forEach((events, latLngStr) => {
                const timeToSnowDepthMap = new Map();
                events.forEach((event) => {
                    timeToSnowDepthMap.set(event.time_in_utc, event.snow_depth_in_cm);
                });
                enhancedWeatherEvents.set(latLngStr, timeToSnowDepthMap);
            });
            return enhancedWeatherEvents;
        }
        return null;
    }, [routeWeatherContext]);
    const enhancedLocations = useMemo(() => {
        return locations.map((location) => {
            if (location.type === 'transition')
                return location;
            const formattedTime = {
                arrivalTime: handleTimeFormat(location.time.arrivalTime, timeFormat),
                departureTime: handleTimeFormat(location.time.departureTime, timeFormat),
            };
            return {
                ...location,
                time: formattedTime,
                snowDepth: enhancedWeatherEvents ?
                    getSnowDepth(location.coords, location.time.arrivalTime, enhancedWeatherEvents) :
                    0,
            };
        });
    }, [locations, enhancedWeatherEvents, timeFormat]);
    return (React.createElement(LocationContext.Provider, { value: enhancedLocations }, children));
}
;
export function useLocations() {
    return useContext(LocationContext);
}
;
