import React, { useEffect, useState } from 'react';
import { AdvancedMarker, InfoWindow, Pin } from '@vis.gl/react-google-maps';

import { useMapContext } from './MapProvider.js';

const DEFAULT_BACKGROUND_HEX_COLOR = '#FF5252'; // red
const DEFAULT_BORDER_HEX_COLOR = '#D32F2F'; // dark red
const DEFAULT_TEXT_COLOR = '#FFFFFF'; // white

export default function LocationMarker({ streetAddress, latLng, polylineHexColor, pinDisplay }) {
    const mapContext = useMapContext();
    const { isGlobalInfoWindowOpen, setGlobalInfoWindowOpen } = mapContext;
    const { setCenter } = mapContext;
    const [isInfoWindowOpen, setInfoWindowOpen] = useState(false);

    let backgroundColor = DEFAULT_BACKGROUND_HEX_COLOR;
    let borderColor = DEFAULT_BORDER_HEX_COLOR;
    let textColor = DEFAULT_TEXT_COLOR;

    useEffect(() => {
        if (!isGlobalInfoWindowOpen) {
            setInfoWindowOpen(false);
        }
    }, [isGlobalInfoWindowOpen]);

    async function handleMarkerClick() {
        // close button on info window has different handler
        if (isGlobalInfoWindowOpen) {
            await setGlobalInfoWindowOpen(false);
        };
        setInfoWindowOpen(true);
        setGlobalInfoWindowOpen(true);
        setCenter(latLng);
    }

    function handleInfoWindowCloseClick() {
        setInfoWindowOpen(false);
        setGlobalInfoWindowOpen(false);
    }

    if (polylineHexColor) {
        backgroundColor = polylineHexColor;
        borderColor = polylineHexColor;
        textColor = DEFAULT_TEXT_COLOR;
    }

    return (
        <AdvancedMarker position={latLng}
            onClick={handleMarkerClick}
        >
            <div style={{ position: 'relative', textAlign: 'center' }}>
                <Pin
                    background={backgroundColor}
                    borderColor={borderColor}
                    glyphColor={textColor}
                >
                    <div style={{
                        backgroundColor: backgroundColor,
                        borderRadius: '50%',
                        width: '24px',
                        height: '24px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'white',
                        fontSize: '12px',
                    }}>
                        {pinDisplay}
                    </div>
                </Pin>
                <span style={{
                    position: 'absolute',
                    top: '50%',  // Adjust these values as needed to center the number
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: '#fff',  // Ensure the number is visible against the pin background
                    fontSize: '12px',  // Adjust the font size as needed
                    fontWeight: 'bold',
                    zIndex: 333,
                }}>
                </span>
                {isInfoWindowOpen && (
                    <InfoWindow
                        className={`info-window`}
                        onCloseClick={() => handleInfoWindowCloseClick()}
                        position={latLng}
                    >
                        <p>
                            {streetAddress}
                        </p>
                    </InfoWindow>
                )}
            </div>
        </AdvancedMarker >
    );
};