import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc.js';
import timezone from 'dayjs/plugin/timezone.js';
import moment from "moment";
dayjs.extend(utc);
dayjs.extend(timezone);
;
export function convertToISO8601(timeInput) {
    let time;
    try {
        const dayjsTimeInput = timeInput instanceof Date ? dayjs(timeInput) : timeInput;
        time = dayjs.utc(dayjsTimeInput).format('YYYY-MM-DDTHH:mm:ss[Z]');
    }
    catch (e) {
        throw new Error(`Unable to convert time ${timeInput} to an ISO 8601 string`);
    }
    return time;
}
;
export function handleTimeFormat(time, format) {
    const localTime = moment.utc(time).local();
    return localTime.format(format);
}
export function roundToNearestHours(time) {
    const roundedDown = time.startOf('hour');
    const isExactHour = time.minute() === 0 && time.second() === 0 && time.millisecond() === 0;
    const nextHour = isExactHour ? roundedDown : roundedDown.add(1, 'hour');
    return {
        hourBefore: roundedDown,
        hourAfter: nextHour,
    };
}
export function interpolateSnowDepth(time1, depth1, time2, depth2, targetTime) {
    if (targetTime.minute() === 0 && targetTime.second() === 0) {
        return null;
    }
    const time1Ms = time1.valueOf();
    const time2Ms = time2.valueOf();
    const targetTimeMs = targetTime.valueOf();
    const slope = (depth2 - depth1) / (time2Ms - time1Ms);
    const interpolatedDepth = depth1 + slope * (targetTimeMs - time1Ms);
    return interpolatedDepth;
}
;
export function interpolateStartTime(time1, depth1, time2, depth2, threshold) {
    const timeDifference = time2.diff(time1, 'milliseconds');
    const depthDifference = depth2 - depth1;
    // const slope = depthDifference / timeDifference;
    if (depthDifference === 0) {
        if (depth1 === threshold) {
            return convertToISO8601(time1);
        }
        else {
            throw new Error("Threshold depth is never reached as there is no change in snow depth.");
        }
    }
    const depthOffset = (threshold - depth1) / depthDifference;
    if (depthOffset < 0 || depthOffset > 1) {
        throw new Error("Threshold is outside the range of provided depths.");
    }
    ;
    const interpolatedTime = time1.add(depthOffset * timeDifference, 'milliseconds');
    return convertToISO8601(interpolatedTime);
}
;
