import React from 'react';
import { Dialog, DialogContent, DialogTitle, Divider, IconButton, Typography } from '@mui/material';
import { useAppSelector } from '../redux/hooks.js';
import MemoizedPaymentOptions from './PaymentOptions.js';
import { CloseIcon } from '../constants/formIcons.js';
export default function PaymentModal({ handleClose, isOpen }) {
    const paymentTotal = useAppSelector((state) => state.simulation.paymentTotal);
    return (React.createElement(Dialog, { open: isOpen, onClose: handleClose, fullWidth: true, maxWidth: "sm", scroll: "paper" },
        React.createElement(DialogTitle, { sx: { position: 'relative', paddingRight: '48px' } },
            React.createElement(Typography, { variant: "h6" },
                "Unlock this route for only $",
                paymentTotal,
                "!"),
            React.createElement(IconButton, { "aria-label": "close", onClick: handleClose, sx: {
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                } },
                React.createElement(CloseIcon, null))),
        React.createElement(DialogContent, { dividers: true },
            React.createElement(Typography, { variant: "body1", paragraph: true },
                "Get access to routes and so much more.",
                React.createElement(Typography, { whiteSpace: "pre" }),
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."),
            React.createElement(Divider, { sx: { my: 2 } }),
            React.createElement(MemoizedPaymentOptions, null))));
}
