import { configureStore } from '@reduxjs/toolkit';
import locationReducer from './locationSlice.js';
import datetimeReducer from './datetimeSlice.js';
import simulationReducer from './simulationSlice.js';
import userReducer from './userSlice.js';
export const store = configureStore({
    reducer: {
        location: locationReducer,
        datetime: datetimeReducer,
        simulation: simulationReducer,
        user: userReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: ['location/handleWeatherEventsResponse'],
            ignoredPaths: ['location.weather.weatherEvents', 'location.weather.contiguousWeatherEvents'],
        },
    }),
});
