import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Button, Grid, LinearProgress, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
import { baseURL } from "../constants/routes.js";
import { useAuthContext } from "./AuthProvider.js";
import { SIMULATION_STATUS } from '../constants/simulationStatus.js';
import { useAppDispatch, useAppSelector } from '../redux/hooks.js';
import generateRequestTimeWindow from "../resources/simulationRequestTransforms.js";
import FirestoreListener from "./FirestoreListener.js";
import { setStatus } from "../redux/simulationSlice.js";
import populateWeatherStore from "../resources/populateWeatherStore.js";
import { handleWeatherEventsResponse } from "../redux/locationSlice.js";
import { HouseIcon } from "../constants/formIcons.js";
function computeEffortMultipier(idealConditionsRevenue, snowDepth, temperatureAt2m) {
    const WEIGHT_OF_SNOW_DEPTH_MULTIPLIER = 1;
    const WEIGHT_OF_SNOW_DENSITY_MULTIPLIER = 1;
    // TODO: If derivative of snow density of fresh snow is different from one for f'n for non-fresh
    //  snow (incl. compaction, solar,etc.), then appropriately calculate effort using ratios
    // (fresh snow/snow depth) * p_fresh snow + (other snow/snow depth) * p_(estimate of other snow) 
    function computeFreshSnowDensity(temperatureAt2m) {
        // source: https://citeseerx.ist.psu.edu/document?repid=rep1&type=pdf&doi=786ec0eaa1163f500317c3dce2bd5b73d389ffbd
        return 67.9 + 51.25 * Math.exp(temperatureAt2m / -2.59);
    }
    const IDEAL_SNOW_DENSITY = 100;
    const snowDensityEstimate = computeFreshSnowDensity(temperatureAt2m);
    const densityFactor = 1 + ((snowDensityEstimate - IDEAL_SNOW_DENSITY) /
        IDEAL_SNOW_DENSITY) * WEIGHT_OF_SNOW_DENSITY_MULTIPLIER;
    const REFERENCE_SNOW_DEPTH_IN_M = 0.01;
    const depthFactor = ((snowDepth / 100) / REFERENCE_SNOW_DEPTH_IN_M) *
        WEIGHT_OF_SNOW_DEPTH_MULTIPLIER;
    return depthFactor * densityFactor;
}
;
export default function StepperSubmitSimulation({ handleBack }) {
    const dispatch = useAppDispatch();
    const authContext = useAuthContext();
    const [simulationId, setSimulationId] = useState('');
    const [simulationUrl, setSimulationUrl] = useState('');
    const status = useAppSelector((state) => state.simulation.status) || SIMULATION_STATUS.vehiclesSelected;
    const [statusMessage, setStatusMessage] = useState('');
    const [excludedShipments, setExcludedShipments] = useState([]);
    const [filteredShipments, setFilteredShipments] = useState([]);
    const [hasFetchedWeatherEvents, setHasFetchedWeatherEvents] = useState(false);
    const referenceLocation = useAppSelector((state) => state.simulation.referenceLocation);
    const snowDepthTrigger = useAppSelector((state) => state.simulation.snowDepthTrigger);
    const lengthUnit = useAppSelector((state) => state.user.lengthUnit);
    const globalStartTimeISO8601 = useAppSelector((state) => state.simulation.request?.optimizationRequest?.model.globalStartTime) || '';
    const globalEndTimeISO8601 = useAppSelector((state) => state.simulation.request?.optimizationRequest?.model.globalEndTime) || '';
    const shipments = useAppSelector((state) => state.simulation.request?.optimizationRequest?.model.shipments) || [];
    const vehicles = useAppSelector((state) => state.simulation.request?.optimizationRequest?.model.vehicles) || [];
    const defaultWeatherEvents = new Map();
    const weatherEvents = useAppSelector(state => state.location.weather?.weatherEvents) || defaultWeatherEvents;
    const defaultContiguousWeatherEvents = new Map();
    const contiguousWeatherEvents = useAppSelector(state => state.location.weather?.contiguousWeatherEvents) || defaultContiguousWeatherEvents;
    const optimizer = {
        "displayName": "",
        "modelSpec": {
            "globalStartTime": globalStartTimeISO8601,
            "globalEndTime": globalEndTimeISO8601,
        },
        "optimizeToursSpec": {
            "timeout": "300s",
            "populatePolylines": true,
            "populateTransitionPolylines": true
        }
    };
    useEffect(() => {
        if (status === SIMULATION_STATUS.requestSent) {
            setStatusMessage(`Thanks for submitting!\nIt should take a 1-2 minutes to generate the routes. This page will automatically update once the result is ready.`);
        }
        else if (status === SIMULATION_STATUS.requestSuccessful) {
            setStatusMessage(`Your result is ready to view now!`);
        }
        else if (status === SIMULATION_STATUS.requestFailed) {
            setStatusMessage(`Your result failed due to an error in our application -- our engineering team will be looking into it.`);
        }
        else {
            setStatusMessage(`Once the simulation completes, your result will be ready in this link.`);
        }
    }, [status]);
    useEffect(() => {
        if (!shipments.length || !snowDepthTrigger || !globalStartTimeISO8601 ||
            !globalEndTimeISO8601 || !authContext.currentUser) {
            return;
        }
        ;
        const locationsCoords = shipments.map(shipment => {
            if (!shipment.pickups.length) {
                throw new Error('Missing pickup from shipment');
            }
            const arrivalLocation = shipment.pickups[0].arrivalLocation;
            return { lat: arrivalLocation.latitude, lng: arrivalLocation.longitude };
        });
        const weatherEventsRequest = {
            locations: locationsCoords,
            startTime: globalStartTimeISO8601,
            endTime: globalEndTimeISO8601,
            snowDepthTrigger: snowDepthTrigger,
        };
        populateWeatherStore(weatherEventsRequest, authContext.currentUser.uid, authContext.idToken).then((weatherEventsResponse) => {
            if (weatherEventsResponse) {
                dispatch(handleWeatherEventsResponse(weatherEventsResponse));
            }
            setHasFetchedWeatherEvents(true);
        }).catch((e) => {
            setHasFetchedWeatherEvents(true);
            console.error(e);
        });
    }, [shipments, authContext, dispatch, globalStartTimeISO8601, globalEndTimeISO8601, snowDepthTrigger]);
    useEffect(() => {
        if (!weatherEvents.size || !contiguousWeatherEvents.size) {
            setFilteredShipments(shipments);
            return;
        }
        ;
        if (!shipments.length || !vehicles.length) {
            return;
        }
        const excludedShipments = [];
        let updatedShipments = shipments.map((visit) => {
            const pickup = visit.pickups[0];
            const visitCoords = `${visit.pickups[0].arrivalLocation.latitude.toFixed(2)}_${visit.pickups[0].arrivalLocation.longitude.toFixed(2)}`;
            const shipmentWeatherEvents = weatherEvents.get(visitCoords) || [];
            const shipmentContiguousWeatherEvents = contiguousWeatherEvents.get(visitCoords) || [];
            //  start time <= WeatherEvent < end time
            // e.g. if the start time is 12:00, the first MMQueryResult in the time window was 12:00
            // e.g. if the end time is 19:00, the last MMQueryResult in the time window was 18:00
            const updatedTimeWindow = generateRequestTimeWindow(pickup, shipmentContiguousWeatherEvents, shipmentWeatherEvents);
            if (!visit.costsPerVehicle.length) {
                throw new Error(`Visit missing shipment cost! :${visit.pickups[0].label}`);
            }
            const shipmentCost = visit.costsPerVehicle[0];
            if (!updatedTimeWindow) {
                excludedShipments.push(visit);
                return null;
            }
            ;
            const updatedShipment = {
                ...visit,
                costsPerVehicle: new Array(vehicles.length).fill(shipmentCost),
                pickups: [{
                        ...pickup,
                        timeWindows: [updatedTimeWindow]
                    }]
            };
            return updatedShipment;
        }).filter(visit => visit !== null);
        setExcludedShipments(excludedShipments);
        setFilteredShipments(updatedShipments);
    }, [shipments, vehicles, contiguousWeatherEvents, weatherEvents]);
    async function handleSubmit() {
        if (!authContext || !authContext.currentUser) {
            throw new Error(`Cannot submit without valid user credentials!: ${JSON.stringify(authContext)}`);
        }
        ;
        if (!snowDepthTrigger) {
            throw new Error(`Missing snow depth threshold!`);
        }
        if (!filteredShipments.length || !vehicles.length) {
            throw new Error(`Missing vehicles or visits from the request!`);
        }
        if (!weatherEvents.size || !contiguousWeatherEvents.size) {
            console.error(`StepperSubmitSimulation: error handling weather events; no filtered out vists`);
        }
        ;
        if (!filteredShipments.length) {
            setStatusMessage(`Given there needs to be ${snowDepthTrigger} ${lengthUnit} snow for removal, there aren't any visits that would require snow removal for their given deadline`);
        }
        const serializedReferenceLocation = referenceLocation ? `${referenceLocation.streetAddress}///${referenceLocation.coords.lat},${referenceLocation.coords.lng}` : '///';
        const simulationRequest = {
            userId: authContext.currentUser['uid'],
            isFastest: true,
            lengthUnit: lengthUnit ? lengthUnit : 'cm',
            referenceLocation: serializedReferenceLocation,
            simulationId: '', // set by server
            snowDepthTrigger: snowDepthTrigger,
            optimizer: optimizer,
            sites: filteredShipments,
            vehicles: vehicles,
        };
        const request = {
            ...simulationRequest,
            uid: authContext.currentUser['uid'],
            idToken: authContext.idToken,
        };
        try {
            const response = await axios.post(`${baseURL}/publish-simulation-request`, request, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (response.status === 200) {
                setSimulationId(response.data.simulationId);
                setSimulationUrl(response.data.simulationUrl);
            }
        }
        catch (e) {
            throw new Error(`Failed to submit simulation-request for uid ${authContext.currentUser['uid']}: ${e}`);
        }
        dispatch(setStatus(SIMULATION_STATUS.requestSent));
    }
    ;
    async function handleCancellation() {
        dispatch(setStatus(SIMULATION_STATUS.vehiclesSelected));
    }
    return (React.createElement(React.Fragment, null,
        !hasFetchedWeatherEvents ?
            React.createElement(Box, { sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                } },
                React.createElement(LinearProgress, { sx: { height: 10, mb: 1 } }),
                React.createElement(Typography, { variant: 'h5' }, "Organizing visits using weather insights"))
            :
                React.createElement(Typography, { variant: "body1", gutterBottom: true }, statusMessage),
        hasFetchedWeatherEvents && excludedShipments.length > 0 && (React.createElement(Grid, { item: true, xs: 12, md: 6 },
            React.createElement(Typography, { sx: { mt: 4, mb: 2 }, variant: "h6", component: "div" }, "\uD83D\uDDD2\uFE0F Visits excluded because they don't get enough snow"),
            React.createElement(List, { dense: false }, excludedShipments.map((shipment, index) => (React.createElement(ListItem, { key: index },
                React.createElement(ListItemIcon, null,
                    React.createElement(HouseIcon, null)),
                React.createElement(ListItemText, { primary: shipment.pickups[0].label.split('///')[0], secondary: null }))))))),
        status >= SIMULATION_STATUS.requestSent ? (React.createElement(React.Fragment, null,
            simulationId &&
                React.createElement(FirestoreListener, { simulationId: simulationId, collectionId: 'results' }),
            status === SIMULATION_STATUS.requestSent && (React.createElement(Box, { sx: { pt: 1 } },
                React.createElement(LinearProgress, null),
                React.createElement(Button, { variant: "outlined", color: "secondary", sx: { mt: 2 }, onClick: () => {
                        handleCancellation();
                        handleBack();
                    } }, "Cancel"))),
            status === SIMULATION_STATUS.requestSuccessful && (React.createElement(Stack, { direction: "row", spacing: 2, sx: { pt: 1 } },
                React.createElement(Button, { color: "primary", variant: "contained", onClick: () => window.open(simulationUrl, '_blank', 'noopener,noreferrer') }, "View Result"),
                React.createElement(Button, { variant: "outlined", onClick: handleBack }, "Back"))))) : (React.createElement(Stack, { direction: "row", spacing: 2, sx: { pt: 1 } },
            React.createElement(Button, { color: "primary", variant: "contained", onClick: handleSubmit, disabled: !hasFetchedWeatherEvents }, "Submit"),
            React.createElement(Button, { variant: "outlined", onClick: handleBack }, "Back")))));
}
;
