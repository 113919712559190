export const SIMULATION_STATUS = {
    'pending': 0,
    'centerSelected': 1,
    'sitesSelected': 2,
    'vehiclesSelected': 3,
    'requestSent': 4,
    'requestSuccessful': 5,
    'requestFailed': 6,
};
export const colorMappingForRoute = {
    1: '#911EB4', // Rich Purple
    2: '#3CB44B', // Strong Green
    3: '#FFE119', // Bright Yellow
    4: '#0082C8', // Bold Blue
    5: '#F58231', // Warm Orange
    6: '#E6194B', // Vivid Red
    7: '#46F0F0', // Cyan
    8: '#F032E6', // Magenta
    9: '#D2F53C', // Lime
    10: '#000000', // Black
};
export const DEFAULT_ROUTE_HEX_COLOR = colorMappingForRoute[1];
export const DEFAULT_BOUNDS = [
    { lat: 43.43424584, lng: -70.5125382 }, // Maine
    { lat: 38.627000, lng: -90.199404 }, // St.Louis,
];
