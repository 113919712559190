import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    currentSimulationId: null,
    distanceUnit: 'km',
    lengthUnit: 'cm',
    locale: 'en',
    simulations: [],
    temperatureUnit: 'C',
    timeFormat: 'MMM DD hh:mm A',
};
export const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        setLengthUnit: (state, action) => {
            state.lengthUnit = action.payload;
        },
        setDistanceUnit: (state, action) => {
            state.distanceUnit = action.payload;
        },
        setTemperatureUnit: (state, action) => {
            state.temperatureUnit = action.payload;
        },
    },
});
const { setDistanceUnit, setLengthUnit, setTemperatureUnit } = userSlice.actions;
export { setDistanceUnit, setLengthUnit, setTemperatureUnit };
export default userSlice.reducer;
