import React, { useState } from 'react';
import { EnterIcon } from '../constants/formIcons.js';
import { isValidEmail } from '../constants/email.js';
import { useAuthContext } from './AuthProvider.js';
import { IconButton, Stack, TextField, Typography } from '@mui/material';
export default function EmailSignInLink() {
    const [email, setEmail] = useState('');
    const [infoMessage, setInfoMessage] = useState('');
    const authContextValue = useAuthContext();
    function handleSubmit(event) {
        event.preventDefault();
        if (!isValidEmail(email)) {
            setInfoMessage(`The e-mail provided: ${email} is not a valid email`);
            return;
        }
        try {
            const refUrl = window.location.href; // either /simulation/* or in UserJourney
            authContextValue.sendEmailSignInLink(email, refUrl);
            setInfoMessage('Check your email for the sign-in link.');
        }
        catch (error) {
            setInfoMessage(error.message);
        }
    }
    ;
    return (React.createElement(Stack, { direction: 'column' },
        React.createElement("form", { style: { display: 'flex', flexDirection: 'row' }, onSubmit: handleSubmit },
            React.createElement(TextField, { type: "email", value: email, onChange: (e) => setEmail(e.target.value), label: "Enter your email", variant: "outlined", required: true, fullWidth: true, size: 'small' }),
            React.createElement(IconButton, { onClick: handleSubmit },
                React.createElement(EnterIcon, { style: { height: '36px', width: '36px' } }))),
        React.createElement(Typography, { variant: 'body1' }, infoMessage)));
}
