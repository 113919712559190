import React, { memo, useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Button, Grid } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../redux/hooks.js';
import { baseURL } from '../constants/routes.js';
import { useAuthContext } from './AuthProvider.js';
import { DEFAULT_CURRENCY, PAYMENT_STATUS } from '../constants/payments.js';
import { setPaymentStatus } from '../redux/simulationSlice.js';
async function initializeApplePay(payments, paymentRequest) {
    const applePay = await payments.applePay(paymentRequest);
    return applePay;
}
async function initializeGooglePay(payments, paymentRequest) {
    const googlePay = await payments.googlePay(paymentRequest);
    await googlePay.attach('#google-pay-button', {
        buttonSizeMode: 'fill',
        buttonType: 'long'
    });
    return googlePay;
}
function PaymentOptions() {
    const dispatch = useAppDispatch();
    const [isSdkReady, setSdkReady] = useState(false);
    const [isPaymentAvailable, setIsPaymentAvailable] = useState(true);
    const [applePayInitialized, setApplePayInitialized] = useState(false);
    const [googlePayInitialized, setGooglePayInitialized] = useState(false);
    const authContext = useAuthContext();
    const paymentCurrency = useAppSelector((state) => state.simulation.paymentCurrency) || DEFAULT_CURRENCY;
    const paymentTotal = useAppSelector((state) => state.simulation.paymentTotal) || null;
    const simulationId = useAppSelector((state) => state.simulation.simulationId);
    useEffect(() => {
        const loadSquareSdk = new Promise((resolve, reject) => {
            const checkVerificationLinkLoaded = () => {
                return document.querySelector('link[href="/.well-known/apple-developer-merchantid-domain-association"]');
            };
            if (window.Square && checkVerificationLinkLoaded()) {
                resolve(null);
            }
            else {
                const script = document.createElement('script');
                if (process.env.REACT_APP_ENV === 'DEV') {
                    script.src = 'https://sandbox.web.squarecdn.com/v1/square.js';
                }
                else {
                    script.src = 'https://web.squarecdn.com/v1/square.js';
                }
                script.onload = () => {
                    if (checkVerificationLinkLoaded()) {
                        resolve(null);
                    }
                    else {
                        reject(new Error('Verification link not found'));
                    }
                };
                script.onerror = reject;
                document.head.appendChild(script);
            }
        });
        loadSquareSdk.then(() => {
            console.log('Square SDK loaded');
            setSdkReady(true);
        }).catch((error) => {
            console.error('Failed to load the Square SDK:', error.message);
        });
    }, []);
    useEffect(() => {
        if (!isSdkReady || !simulationId || !authContext.currentUser ||
            !authContext.idToken || !authContext.currentUser.email) {
            return;
        }
        if (!paymentTotal || !paymentCurrency) {
            console.error(`Payment total and currency not available for simulationId ${simulationId}!`);
            return;
        }
        if (!simulationId) {
            return;
        }
        async function initializePayments() {
            let payments;
            try {
                payments = window.Square.payments(process.env.REACT_APP_SQUARE_APPLICATION_ID, process.env.REACT_APP_SQUARE_LOCATION_ID);
            }
            catch (e) {
                console.error(`Failed to initialize payments`, e);
            }
            const paymentRequestBody = {
                countryCode: 'CA',
                currencyCode: paymentCurrency,
                total: {
                    amount: paymentTotal.toString(),
                    label: 'Minuteman -- Route Planning Service',
                },
            };
            async function initializePayment(paymentType, paymentRequestBody, payments, buttonId, initializeFunction, setInitialized) {
                const paymentButton = document.getElementById(buttonId);
                if (paymentButton) {
                    const paymentRequest = payments.paymentRequest(paymentRequestBody);
                    const paymentInstance = await initializeFunction(payments, paymentRequest);
                    setInitialized(true);
                    paymentButton.addEventListener('click', async function (event) {
                        setIsPaymentAvailable(false);
                        try {
                            if (paymentInstance) {
                                const tokenResult = await paymentInstance.tokenize();
                                if (tokenResult.status === 'OK') {
                                    if (!tokenResult.token) {
                                        setIsPaymentAvailable(true);
                                        throw new Error(`Successful tokenization but missing token from token result`);
                                    }
                                    console.log(`${paymentType} payment token is ${tokenResult.token}`);
                                    const response = await axios.post(`${baseURL}/payment`, {
                                        simulationId: simulationId,
                                        sourceId: tokenResult.token,
                                        paymentCurrency: paymentCurrency,
                                        paymentTotal: paymentTotal,
                                        email: authContext.currentUser.email,
                                        uid: authContext.currentUser.uid,
                                        idToken: authContext.idToken,
                                    }, {
                                        headers: {
                                            'Content-Type': 'application/json',
                                        }
                                    });
                                    if (response.status !== 200) {
                                        setIsPaymentAvailable(true);
                                        console.error(`Failed to process payment`);
                                    }
                                    else {
                                        dispatch(setPaymentStatus(PAYMENT_STATUS.COMPLETED));
                                        console.log(`Successfully processed payment! Payment ID ${response.data}`);
                                    }
                                }
                                else {
                                    let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
                                    if (tokenResult.errors) {
                                        errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
                                    }
                                    setIsPaymentAvailable(true);
                                    console.error(errorMessage);
                                }
                            }
                        }
                        catch (e) {
                            setIsPaymentAvailable(true);
                            console.error(`Failed during ${paymentType} initialization`, e);
                        }
                    });
                }
            }
            ;
            if (!googlePayInitialized) {
                await initializePayment('Google Pay', paymentRequestBody, payments, 'google-pay-button', initializeGooglePay, setGooglePayInitialized);
            }
            if (!applePayInitialized && process.env.REACT_APP_ENV === 'PROD') {
                await initializePayment('Apple Pay', paymentRequestBody, payments, 'apple-pay-button', initializeApplePay, setApplePayInitialized);
            }
        }
        initializePayments();
    }, [dispatch, authContext, isSdkReady, applePayInitialized, googlePayInitialized, paymentCurrency, paymentTotal, simulationId]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { component: "form", id: "payment-form" }, isPaymentAvailable ? (React.createElement(Grid, { container: true, spacing: 2, justifyContent: "center", flexDirection: 'column' },
            React.createElement(Grid, { item: true, xs: 12, sm: 6, sx: {
                    display: (applePayInitialized && process.env.REACT_APP_ENV === 'PROD') ?
                        'flex' :
                        'none',
                    margin: '0 auto'
                } },
                React.createElement(Box, { id: "apple-pay-button", sx: {
                        height: '48px',
                        width: '20rem',
                        cursor: 'pointer',
                        WebkitAppearance: '-apple-pay-button',
                    }, "aria-label": "Apple Pay" })),
            React.createElement(Grid, { item: true, xs: 12, sm: 6, sx: {
                    display: googlePayInitialized ? 'flex' : 'none',
                    margin: '0 auto'
                } },
                React.createElement(Box, { id: "google-pay-button", sx: {
                        height: '48px',
                        width: '20rem',
                        backgroundColor: '#fff',
                        cursor: 'pointer',
                    }, "aria-label": "Google Pay" })),
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { id: "card-container" })))) :
            React.createElement(Grid, { container: true, spacing: 2, justifyContent: "center", flexDirection: 'column' },
                React.createElement(Grid, { item: true, xs: 12, sm: 6, sx: {
                        display: 'flex',
                        margin: '0 auto'
                    } },
                    React.createElement(Button, { id: "processing-payment-button", sx: {
                            height: '48px',
                            width: '20rem',
                            backgroundColor: (theme) => theme.palette.primary.contrastText,
                            color: (theme) => theme.palette.primary.main,
                        }, "aria-label": "Processing payment" }, "Processing..."))))));
}
const MemoizedPaymentOptions = memo(PaymentOptions);
export default MemoizedPaymentOptions;
