export const PAYMENT_STATUS = {
    'PENDING': 0,
    'COMPLETED': 1,
    'FAILED': 2,
    'APPROVED': 3,
    'CANCELED': 4,
};
export const DEFAULT_CURRENCY = 'CAD';
export const HARDCODED_CAD_TO_USD_RATE = 0.75;
export const FIXED_TRANSACTION_FEE_IN_CENTS = 30;
export const VARIABLE_TRANSACTION_FEE_IN_PERCENT = 0.029;
export const COST_PER_VISIT_IN_CENTS = 40;
