import { createSlice } from '@reduxjs/toolkit';
import { SIMULATION_STATUS } from '../constants/simulationStatus.js';
import { DEFAULT_CURRENCY, PAYMENT_STATUS } from '../constants/payments.js';
const initialState = {
    paymentCurrency: DEFAULT_CURRENCY,
    paymentNotificationSent: false,
    paymentStatus: PAYMENT_STATUS.PENDING,
    simulationId: null,
    status: SIMULATION_STATUS.pending,
};
export const simulationSlice = createSlice({
    name: 'simulation',
    initialState: initialState,
    reducers: {
        setPaymentNotificationSent: (state, action) => {
            state.paymentNotificationSent = action.payload;
        },
        setPaymentStatus: (state, action) => {
            state.paymentStatus = action.payload;
        },
        setPaymentTotal: (state, action) => {
            state.paymentTotal = action.payload.paymentTotal;
            state.paymentCurrency = action.payload.paymentCurrency;
        },
        setReferenceLocation: (state, action) => {
            const { streetAddress, coords } = action.payload;
            state.referenceLocation = {
                streetAddress,
                coords,
            };
        },
        setRequest: (state, action) => {
            state.request = action.payload;
        },
        setRequestVisits: (state, action) => {
            if (state.request && state.request.optimizationRequest) {
                state.request.optimizationRequest.model.shipments = action.payload;
            }
        },
        setRequestVehicles: (state, action) => {
            if (state.request && state.request.optimizationRequest) {
                state.request.optimizationRequest.model.vehicles = action.payload;
            }
        },
        setResult: (state, action) => {
            state.result = action.payload;
        },
        setSnowDepthTrigger: (state, action) => {
            state.snowDepthTrigger = action.payload;
        },
        setSimulationId: (state, action) => {
            state.simulationId = action.payload;
        },
        setStatus: (state, action) => {
            state.status = action.payload;
        },
    },
});
const { setPaymentNotificationSent, setPaymentStatus, setPaymentTotal, setReferenceLocation, setRequest, setRequestVehicles, setRequestVisits, setResult, setSnowDepthTrigger, setSimulationId, setStatus } = simulationSlice.actions;
export { setPaymentNotificationSent, setPaymentStatus, setPaymentTotal, setReferenceLocation, setRequest, setRequestVehicles, setRequestVisits, setResult, setSnowDepthTrigger, setSimulationId, setStatus };
export default simulationSlice.reducer;
