import React from 'react';
import { APIProvider } from '@vis.gl/react-google-maps';
import { Stack, Paper } from '@mui/material';

import DeviceDetect from './DeviceDetect.js';
import Map from './Map.js';
import RouteGrid from './RouteGrid.js';
import MapProvider from './MapProvider.js';
import MemoizedRouteProvider from './RouteProvider.js';

export default function SimulationResult() {
    const { isMobile, isTablet } = DeviceDetect();
    const isDesktop = !isMobile && !isTablet;
    return (
        <Stack
            className="SimulationResult"
            sx={{ height: '100vh', overflow: 'hidden' }}
        >
            <Paper
                className="left-panel"
                sx={{
                    height: isDesktop ? '400px' : '50vh',
                    paddingBottom: '10px',
                    boxSizing: 'border-box',
                    borderRadius: 2,
                    marginBottom: 2,
                }}
                elevation={3}
            >
                <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
                    <MapProvider>
                        <Map />
                    </MapProvider>
                </APIProvider>
            </Paper>
            <Paper
                className="right-panel"
                sx={{
                    flexGrow: 1,
                    overflowY: 'auto',
                    boxSizing: 'border-box',
                    borderRadius: 2,
                    minHeight: 0,
                }}
                elevation={3}
            >
                <MemoizedRouteProvider>
                    <RouteGrid />
                </MemoizedRouteProvider>
            </Paper>
        </Stack>
    );
    
};